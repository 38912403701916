<template>
  <div>
    <h2>Dissolution d'un sel: données + calculs</h2>
    <v-form>
      <!-- Data Table -->
      <h3 class="pt-6 pb-4">Tableau de données</h3>
      <v-textarea
        v-model="inputs.data_table_caption"
        label="Légende"
        outlined
        placeholder="Rédigez une légende pour le tableau des données ici."
      />
      <v-simple-table>
        <thead>
          <tr>
            <th>&nbsp;</th>
            <th>Unités</th>
            <th>Essai 1</th>
            <th>Essai 2</th>
          </tr>
        </thead>
        <tr>
          <th>Identité</th>
          <td>&nbsp;</td>
          <td v-for="i in 2" :key="`known-${i}`">
            <v-text-field v-model="inputs[`trial${i}_identity`]" outlined hide-details />
          </td>
        </tr>
        <tr v-for="{label, key, units: rowUnits} in dataTableRows" :key="key">
          <th><stemble-latex :content="label" /></th>
          <td>
            <v-select
              v-if="!!rowUnits"
              v-model="inputs[`${key}_units`]"
              :items="units"
              item-value="key"
              outlined
              hide-details
            >
              <template #item="{item}">
                <stemble-latex :content="item.label" />
              </template>
              <template #selection="{item}">
                <stemble-latex class="no-text-transform" :content="item.label" />
              </template>
            </v-select>
            <span v-else>&nbsp;</span>
          </td>
          <td v-for="i in 2" :key="`known-${key}-${i}`">
            <calculation-input v-model="inputs[`trial${i}_${key}`]" />
          </td>
        </tr>
      </v-simple-table>

      <!-- Calculations -->
      <h3 class="pt-8 pb-3">Calculations</h3>
      <v-textarea
        v-model="inputs.calculations_table_caption"
        label="Légende"
        outlined
        placeholder="Rédigez une légende pour le tableau des valeurs calculées ici"
      />
      <v-simple-table>
        <thead>
          <tr>
            <th>&nbsp;</th>
            <th>Unités</th>
            <th>Essai 1</th>
            <th>Essai 2</th>
          </tr>
        </thead>
        <tr
          v-for="{label, key, units: rowUnits, type: inputType} in calculationTableRows"
          :key="key"
        >
          <th><stemble-latex :content="label" /></th>
          <td>
            <v-select
              v-if="!!rowUnits"
              v-model="inputs[`${key}_units`]"
              :items="units"
              item-value="key"
              outlined
              hide-details
            >
              <template #item="{item}">
                <stemble-latex :content="item.label" />
              </template>
              <template #selection="{item}">
                <stemble-latex class="no-text-transform" :content="item.label" />
              </template>
            </v-select>
            <span v-else>&nbsp;</span>
          </td>
          <td v-for="i in 2" :key="`known-${key}-${i}`">
            <v-textarea
              v-if="inputType === 'string'"
              v-model="inputs[`trial${i}_${key}`]"
              outlined
              solo
              rows="3"
            />
            <calculation-input v-else v-model="inputs[`trial${i}_${key}`]" />
          </td>
        </tr>
      </v-simple-table>

      <h3 class="pt-6 pb-4">Exemple de calcul</h3>
      <p>
        Téléchargez un exemple de calcul pour l'un ou l'autre essai pour le calcul de l'enthalpie de
        dissolution du sel et le % d'erreur.
      </p>
      <v-file-input v-model="sampleCalculationUploads" multiple />

      <h3 class="pt-6 pb-4">Graphique</h3>
      <p>
        Tracez vos données température-temps. Assurez-vous de placer les données correctes sur le
        bon axe. Étiquetez vos axes et indiquez les unités. Reportez-vous à la figure 2.1 dans les
        graphiques section dans le protocole expérimental et tracez la ligne verticale au moment du
        mélange. Alors extrapoler au moment du mélange à partir de votre température finale
        stabilisée (cela peut être horizontal ou incliné selon vos données).
      </p>
      <v-file-input v-model="graphUploads" multiple />
      <v-textarea
        v-model="inputs.graphCaption"
        outlined
        label="Légende de figure"
        placeholder="Rédigez une légende pour la figure ici."
      />
    </v-form>
  </div>
</template>

<script>
import DynamicQuestionMixin from '../../mixins/dynamic-question';
import CalculationInput from '@/tasks/components/inputs/CalculationInput.vue';
import StembleLatex from '@/tasks/components/StembleLatex.vue';

export default {
  name: 'OttawaLab1ReportTask2Data',
  components: {StembleLatex, CalculationInput},
  mixins: [DynamicQuestionMixin()],
  data() {
    return {
      inputs: {
        data_table_caption: null,
        calculations_table_caption: null,
        graphCaption: null,

        molar_mass_salt_units: null,
        mass_salt_units: null,
        mass_calorimeter_units: null,
        volume_water_units: null,
        mass_calorimeter_and_water_units: null,
        initial_temp_water_units: null,
        final_temp_mixture_units: null,
        mass_calorimeter_and_contents_units: null,
        mass_water_units: null,
        delta_temp_water_units: null,
        specific_heat_water_units: null,
        delta_temp_salt_units: null,
        literature_specific_heat_salt_units: null,
        moles_salt_units: null,
        enthalpy_salt_units: null,
        literature_enthalpy_salt_units: null,
        percent_error_enthalpy_units: null,

        trial1_identity: null,
        trial2_identity: null,
        trial1_molar_mass_salt: null,
        trial2_molar_mass_salt: null,
        trial1_mass_salt: null,
        trial2_mass_salt: null,
        trial1_mass_calorimeter: null,
        trial2_mass_calorimeter: null,
        trial1_volume_water: null,
        trial2_volume_water: null,
        trial1_mass_calorimeter_and_water: null,
        trial2_mass_calorimeter_and_water: null,
        trial1_initial_temp_water: null,
        trial2_initial_temp_water: null,
        trial1_final_temp_mixture: null,
        trial2_final_temp_mixture: null,
        trial1_mass_calorimeter_and_contents: null,
        trial2_mass_calorimeter_and_contents: null,
        trial1_mass_water: null,
        trial2_mass_water: null,
        trial1_delta_temp_water: null,
        trial2_delta_temp_water: null,
        trial1_specific_heat_water: null,
        trial2_specific_heat_water: null,
        trial1_reference_specific_heat_water: null,
        trial2_reference_specific_heat_water: null,
        trial1_delta_temp_salt: null,
        trial2_delta_temp_salt: null,
        trial1_literature_specific_heat_salt: null,
        trial2_literature_specific_heat_salt: null,
        trial1_reference_specific_heat_salt: null,
        trial2_reference_specific_heat_salt: null,
        trial1_moles_salt: null,
        trial2_moles_salt: null,
        trial1_enthalpy_salt: null,
        trial2_enthalpy_salt: null,
        trial1_literature_enthalpy_salt: null,
        trial2_literature_enthalpy_salt: null,
        trial1_reference_literature_enthalpy_salt: null,
        trial2_reference_literature_enthalpy_salt: null,
        trial1_percent_error_enthalpy: null,
        trial2_percent_error_enthalpy: null,
      },
      graphUploads: [],
      sampleCalculationUploads: [],
      units: [
        {key: 'g', label: '$\\text{g}$'},
        {key: 'C', label: '$^{\\omicron}\\text{C}$'},
        {key: 'mL', label: '$\\text{mL}$'},
        {key: 'kJPerMol', label: '$\\text{kJ} \\cdot \\text{mol}^{-1}$'},
        {key: 'mol', label: '$\\text{mol}$'},
        {key: 'gPerMol', label: '$\\text{g} \\cdot \\text{mol}^{-1}$'},
        {
          key: 'JGC',
          label: '$\\text{J} \\cdot \\text{g}^{-1} \\cdot {^{\\omicron}\\text{C}}^{-1}$',
        },
        {
          key: 'percent',
          label: '$\\%$',
        },
      ],
      dataTableRows: [
        {label: 'Masse molaire du sel', key: 'molar_mass_salt', units: true},
        {label: 'Masse du sel', key: 'mass_salt', units: true},
        {label: 'Mass of calorimètre', key: 'mass_calorimeter', units: true},
        {label: "Volume d'eau", key: 'volume_water', units: true},
        {label: 'Masse (eau + calorimètre)', key: 'mass_calorimeter_and_water', units: true},
        {label: "Température initiale de l'eau", key: 'initial_temp_water', units: true},
        {label: 'Température du mélange', key: 'final_temp_mixture', units: true},
        {label: 'Masse (eau + solution)', key: 'mass_calorimeter_and_contents', units: true},
      ],
      calculationTableRows: [
        {label: "Masse d'eau", key: 'mass_water', units: true},
        {label: '$\\Delta T_{eau}$', key: 'delta_temp_water', units: true},
        {label: '$c_{eau}$', key: 'specific_heat_water', units: true},
        {
          label: 'Référence pour $c_{eau}$',
          key: 'reference_specific_heat_water',
          units: false,
          type: 'string',
        },
        {label: '$\\Delta T_{sel}$', key: 'delta_temp_salt', units: true},
        {label: '$c_{sel}$ (littérature)', key: 'literature_specific_heat_salt', units: true},
        {
          label: 'Référence pour $c_{sel}$',
          key: 'reference_specific_heat_salt',
          units: false,
          type: 'string',
        },
        {label: 'Quantité de sel', key: 'moles_salt', units: true},
        {label: '$\\Delta_s H^\\omicron$ (expérimentale)', key: 'enthalpy_salt', units: true},
        {
          label: '$\\Delta_s H^\\omicron$ (littérature)',
          key: 'literature_enthalpy_salt',
          units: true,
        },
        {
          label: 'Référence pour $\\Delta_s H^\\omicron$',
          key: 'reference_literature_enthalpy_salt',
          units: false,
          type: 'string',
        },
        {label: "Pourcentage d'erreur", key: 'percent_error_enthalpy', units: true},
      ],
    };
  },
  computed: {
    attachments() {
      return [...this.graphUploads, ...this.sampleCalculationUploads];
    },
  },
};
</script>
